import React, { Dispatch, SetStateAction } from 'react';

export default function V12Hero({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <>
      <div className="bg-gray-600 text-center">
        <div className="max-w-screen-xl mx-auto leading-3 md:leading-none py-1 text-center">
          <span className="text-white text-3xs md:text-xs italic px-6 md:px-3">
            We are not associated with the UK Government. We operate as a
            third-party agency and charge a £78 service fee. You can choose to
            apply for a UK driving licence directly through the official UK
            Government website at <a href="https://gov.uk">gov.uk</a> without
            using our services.
          </span>
        </div>
      </div>
      <div className="bg-v12BG bg-cover bg-center flex flex-col items-start justify-items-center p-2 sm:p-12 py-24  xl:p-32 gap-6">
        <h1 className="text-white text-3xl sm:text-5xl align-text-left font-black max-w-lg">
          Your Driving Licence Application Online{' '}
        </h1>
        <h2 className="text-white text-xl sm:text-2xl align-text-left font-bold">
          Renew, replace, change or apply for a driving licence
        </h2>
        <p className="text-white text-md sm:text-lg align-text-left font-thin max-w-xl">
          We expedite your driving licence application process by thoroughly
          reviewing and rectifying any potential errors or omissions, ensuring a
          swift and accurate submission to DVLA.
        </p>
        <button
          className="text-white text-xl px-12 py-4 bg-red-500 hover:bg-red-600 rounded-2xl font-black"
          onClick={() => setShowModal(true)}
        >
          Start Your Application
        </button>
      </div>
    </>
  );
}
