import Head from 'next/head';
import { ReactNode } from 'react';
import { Theme } from '@/contexts/ThemeContext';

export default function WebsiteHead({
  title,
  children,
  theme,
}: {
  title: string;
  children?: ReactNode;
  theme?: Theme;
}): JSX.Element {
  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta
        name="description"
        content={`Use our simple step by step solution to apply, renew and replace your UK ${
          theme && theme.slug !== 'eyesol' && 'Driver’s '
        }Licence.`}
      />
      <link rel="icon" href="/favicon.ico" sizes="32x32" />
      {children}
    </Head>
  );
}
