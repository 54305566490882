import { Dispatch, SetStateAction } from 'react';

export default function Pricing({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <div className="flex flex-col items-center justify-center sm:p-24">
      <h1 className="text-3xl text-center font-black">
        Experience the Future of Productivity Today
      </h1>
      <div className="flex flex-col lg:flex-row items-center justify-around gap-12 sm:gap-24 p-8 py-12 ">
        <div>
          <img src="/images/driver-license.png" alt="" />
        </div>
        <div className="flex flex-col items-start justify-start gap-6 sm:gap-12">
          <h1 className="text-3xl text-left font-black">£78</h1>
          <ol className="text-center list-disc">
            <li className="text-left text-md">All-in admin support</li>
            <li className="text-left text-md">Loyalty program</li>
            <li className="text-left text-md">SMS and email notifications</li>
            <li className="text-left text-md">All-week Customer Support</li>
            <li className="text-left text-md">
              Free lost driving licence replacement
            </li>
          </ol>
          <button
            className="text-white px-12 py-4 bg-red-500 text-xl hover:bg-red-600 rounded-2xl font-black w-auto"
            onClick={() => setShowModal(true)}
          >
            Start Your Application
          </button>
        </div>
      </div>
    </div>
  );
}
