import React from 'react';

export default function Reviews(): React.ReactElement {
  return (
    <div className="bg-gray-200 sm:p-16 p-6 flex flex-col items-center justify-center gap-24">
      <h1 className="text-3xl text-center font-black">
        What they think about us
      </h1>
      <div className="flex items-start justify-center gap-16 md:flex-row flex-col">
        <div className="flex flex-col items-start justify-center max-w-4 gap-3">
          <img src="/images/5-star.png" alt="" />
          <p className="text-left  max-w-xs">
            I couldn&apos;t be happier with the experience. The user-friendly
            interface made the entire process a breeze. From filling out the
            application to uploading required documents, everything was
            straightforward and intuitive.
          </p>
          <div className="flex items-center justify-start gap-6">
            <img src="/images/christiane.png" alt="" />
            <p className="font-medium">Christiane A.</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center max-w-4 gap-3">
          <img src="/images/5-star.png" alt="" />
          <p className="text-left max-w-xs">
            I was pleasantly surprised by the speed at which my application was
            processed, and I received my licence in no time. Thank you, for
            making a potentially complicated process so easy!
          </p>
          <div className="flex items-center justify-start gap-6">
            <img src="/images/jennifer.png" alt="" />
            <p className="font-medium">Jennifer R.</p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-center max-w-4 gap-3">
          <img src="/images/5-star.png" alt="" />
          <p className="text-left max-w-xs">
            I was in a hurry to get my driving licence and I received it faster
            than expected. Their efficient process made the whole experience
            smooth.
          </p>
          <div className="flex items-center justify-start gap-6">
            <img src="/images/gary.png" alt="" />
            <p className="font-medium">Gary W.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
