import React, { Dispatch, SetStateAction } from 'react';

export default function Services({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <div className="p-6 sm:p-12 flex flex-col items-center justify-center sm:gap-24 gap-12 min-w-xl py-16 ">
      <h1 className="text-3xl text-center font-black">Our Services</h1>
      <div className="flex sm:flex-row flex-col justify-between items-center sm:gap-24 gap-12">
        <div className="flex flex-col items-start justify-center gap-6 ">
          <div className="flex items-center justify-start gap-6">
            <img src="/images/efficiency.png" alt="" />
            <h1 className="text-xl font-black">Efficiency</h1>
          </div>
          <p className="max-w-xs">
            Our service is all about saving your valuable time for your driving
            licence applications. We have eliminated all unnecessary steps and
            waiting times! This ensures you can complete your application
            swiftly so you get behind the wheel in no time!
          </p>
        </div>
        <div className="flex flex-col items-start justify-center gap-6 ">
          <div className="flex items-center justify-start gap-6">
            <img src="/images/loyalty.png" alt="" />
            <h1 className="text-xl font-black">Loyalty program</h1>
          </div>
          <p className="max-w-xs">
            Get more for less! Reward yourself with an array of exclusive
            discounts and exciting offers spanning a wide variety of products
            and services, from some of the most renowned UK retailers! Simply
            log in to your dedicated member portal to access a variety of great
            deals, regularly refreshed for your benefit.
          </p>
        </div>
        <div className="flex flex-col items-start justify-center gap-6 ">
          <div className="flex items-center justify-start gap-6">
            <img src="/images/legal-assistance.png" alt="" />
            <h1 className="text-xl font-black">Customer Support</h1>
          </div>
          <p className="max-w-xs">
            Our admin platform offers exceptional 24/7 customer support. Our
            dedicated team is always available to assist you with any queries or
            issues, ensuring a seamless and positive experience around the
            clock. Your satisfaction is our top priority.
          </p>
        </div>
      </div>
      <button
        className="text-white px-12 py-4 text-xl bg-red-500 hover:bg-red-600 rounded-2xl font-black"
        onClick={() => setShowModal(true)}
      >
        Start Your Application
      </button>
    </div>
  );
}
