import { Dispatch, SetStateAction } from 'react';

export default function Pricing({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <div className="flex flex-col items-center justify-center gap-16 p-10 sm:p-24 min-w-xl">
      <h1 className="text-3xl text-center font-black">
        Experience the Future of Productivity Today
      </h1>
      <div className="flex flex-col lg:flex-row items-center justify-around gap-12 sm:gap-24">
        <div>
          <img src="/images/license-id.png" alt="" />
        </div>
        <div className="flex flex-col items-center justify-between gap-12">
          <h1 className="text-3xl text-center font-black sm:pr-0 pr-12 ">
            £78
          </h1>
          <ol className="text-center list-disc">
            <li className="text-left text-lg">All-in admin support</li>
            <li className="text-left text-lg">Initial legal assistance</li>
            <li className="text-left text-lg">Loyalty program</li>
            <li className="text-left text-lg">SMS and email notifications</li>
            <li className="text-left text-lg">All-week Customer Support</li>
          </ol>
        </div>
      </div>
      <button
        className="text-white  px-12 py-4 bg-red-500 text-xl hover:bg-red-600 rounded-2xl font-black sm:w-auto"
        onClick={() => setShowModal(true)}
      >
        Start Your Application
      </button>
    </div>
  );
}
