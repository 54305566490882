import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

export default function V13Hero({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 1020); // Or any other breakpoint you choose
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <>
      <div className="bg-gray-600 text-center">
        <div className="max-w-screen-xl leading-3 md:leading-none mx-auto py-1 text-center">
          <span className="text-white text-3xs md:text-xs italic px-6 md:px-3">
            We are not associated with the UK Government. We operate as a
            third-party agency and charge a £78 service fee. You can choose to
            apply for a UK driving licence directly through the official UK
            Government website at <a href="https://gov.uk">gov.uk</a> without
            using our services.
          </span>
        </div>
      </div>
      <div className="flex items-center flex-col justify-evenly xl:flex-row p-4">
        <div className="flex flex-col items-start justify-items-center p-4 xl:p-32 gap-6">
          <h1 className="text-black text-3xl sm:text-5xl align-text-left font-black">
            Your Driving Licence <br /> Application Online{' '}
          </h1>
          <h2 className="text-black text-xl sm:text-2xl align-text-left font-medium">
            Renew, replace, change or apply for a driving licence
          </h2>
          <p className="text-black text-md sm:text-lg align-text-left font-thin">
            We expedite your driving licence application process by thoroughly
            reviewing and <br /> rectifying any potential errors or omissions,
            ensuring a swift and accurate submission <br /> to DVLA.
          </p>
          {!isMobile && (
            <button
              className="text-white px-12 py-4 text-xl bg-red-500 hover:bg-red-600 rounded-2xl font-black"
              onClick={() => setShowModal(true)}
            >
              Start Your Application
            </button>
          )}
        </div>
        <div>
          <img
            className={`p-4 ${
              isMobile && 'w-1/2 h-84 place-self-center ml-1/4'
            } `}
            src="/images/lady-in-car.png"
            alt=""
          />
        </div>
        {isMobile && (
          <button
            className="text-white px-12 py-4 bg-red-500 text-xl hover:bg-red-600 rounded-2xl font-black"
            onClick={() => setShowModal(true)}
          >
            Start Your Application
          </button>
        )}
      </div>
    </>
  );
}
