import React, { Dispatch, SetStateAction } from 'react';

export default function Apply({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <div className="p-8 flex flex-col items-center justify-center bg-gray-200 min-w-xl py-16">
      <h1 className="text-3xl text-center pb-12 font-black">
        Apply in minutes
      </h1>
      <div className="flex flex-col lg:flex-row align-center justify-around gap-6 sm:gap-24">
        <div className="flex flex-col align-start justify-start p-6 gap-3">
          <img className="self-center" src="/images/dl-type.png" alt="" />
          <p className="text-center font-black max-w-xs">
            Select your type of driving licence application
          </p>
        </div>
        <div className="flex items-center justify-center">
          <img
            className="rotate-90 transform lg:rotate-0 max-h-6 sm:max-h-8"
            src="/images/arrow.png"
            alt=""
          />
        </div>
        <div className="flex flex-col align-start justify-start p-6 gap-3">
          <img className="self-center" src="/images/dl-details.png" alt="" />
          <p className="text-center font-black max-w-xs">
            Provide the necessary details
          </p>
        </div>
        <div className="flex items-center justify-center">
          <img
            className="rotate-90 transform lg:rotate-0 max-h-6 sm:max-h-8"
            src="/images/arrow.png"
            alt=""
          />
        </div>
        <div className="flex flex-col align-start justify-start p-6 gap-3">
          <img className=" self-center" src="/images/dl-shipping.png" alt="" />
          <p className="text-center font-black max-w-xs">
            Sit back, relax and receive your driving licence
          </p>
        </div>
      </div>
      <button
        className="text-white px-12 py-4 text-xl bg-red-500 hover:bg-red-600 rounded-2xl font-black w-auto"
        onClick={() => setShowModal(true)}
      >
        Start Your Application
      </button>
    </div>
  );
}
