import React, { useState } from 'react';
import Hero from './Hero';
import Modal from '../FormSelectorModal';
import Apply from '../V12/Apply';
import Services from '../V12/Services';
import Pricing from './Pricing';
import Reviews from '../V12/Reviews';
import Reassurance from '../V12/Reassurance';

export default function V13Landing(): React.ReactElement {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <Modal setShowModal={setShowModal} showModal={showModal} />}
      <Hero setShowModal={setShowModal} />
      <Apply setShowModal={setShowModal} />
      <Services setShowModal={setShowModal} />
      <Pricing setShowModal={setShowModal} />
      <Reviews />
      <Reassurance setShowModal={setShowModal} />
    </>
  );
}
