import V12Hero from './Hero';
import Apply from './Apply';
import Services from './Services';
import Pricing from './Pricing';
import Reassurance from './Reassurance';
import React, { useState } from 'react';
import Modal from '../FormSelectorModal';

export default function V12LandingPage(): React.ReactElement {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <V12Hero setShowModal={setShowModal} />
      <Apply setShowModal={setShowModal} />
      <Services setShowModal={setShowModal} />
      <Pricing setShowModal={setShowModal} />
      <Reassurance setShowModal={setShowModal} />
    </>
  );
}
