import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

export default function Modal({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  const router = useRouter();

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showModal]);

  const handleRedirection = (url: string) => {
    setShowModal(false);
    router.push(url);
  };

  return (
    showModal && (
      <>
        <div className="justify-center items-center flex overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-xs sm:max-w-3xl">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex flex-col items-center justify-center gap-3 sm:gap-6 bg-white z-50 sm:p-14 p-4">
                <button
                  className="self-end md:-mt-5"
                  onClick={() => setShowModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <button
                  onClick={() => handleRedirection('/renewal')}
                  className="w-full bg-red-500 hover:bg-red-600 rounded-xl text-white sm:text-lg text-sm p-3 sm:p-6 font-black"
                >
                  Renew your licence (10 years renewal){' '}
                </button>
                <button
                  onClick={() => handleRedirection('/renewal-old')}
                  className="w-full bg-red-500 hover:bg-red-600 rounded-xl text-white sm:text-lg text-sm p-3 sm:p-6 font-black"
                >
                  Renew your licence if you are 70 or over{' '}
                </button>
                <button
                  onClick={() => handleRedirection('/address-change')}
                  className="w-full bg-red-500 hover:bg-red-600 rounded-xl text-white sm:text-lg text-sm p-3 sm:p-6 font-black"
                >
                  Change the address on your licence{' '}
                </button>
                <button
                  onClick={() => handleRedirection('/replacement')}
                  className="w-full bg-red-500 hover:bg-red-600 rounded-xl text-white sm:text-lg text-sm p-3 sm:p-6 font-black"
                >
                  Replace a lost, stolen, destroyed licence{' '}
                </button>
                <button
                  onClick={() => handleRedirection('/provisional')}
                  className="w-full bg-red-500 hover:bg-red-600 rounded-xl text-white sm:text-lg text-sm p-3 sm:p-6 font-black"
                >
                  Apply for your first provisional licence{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
}
