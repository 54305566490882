import React, { Dispatch, SetStateAction } from 'react';

export default function Reassurance({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement {
  return (
    <div className="flex flex-col items-center justify-center p-6 sm:p-12 gap-12">
      <h1 className="text-3xl sm:text-5xl text-center font-black">
        Government-grade service, minus the bureaucracy
      </h1>
      <button
        className="text-white px-12 py-4 bg-red-500 text-xl hover:bg-red-600 rounded-2xl font-black w-auto"
        onClick={() => setShowModal(true)}
      >
        Start Your Application
      </button>
    </div>
  );
}
