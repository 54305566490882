import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { GetServerSideProps } from 'next';
import { useStateMachine } from 'little-state-machine';

import Head from '@/components/Head';
import { useTheme } from '@/contexts/ThemeContext';
import { getTheme, resetForm } from '@/utils/helpers';
import V12LandingPage from '@/components/V12/V12Landing';
import V13Landing from '@/components/V13/V13Landing';

const V1Selector = dynamic(() => import('@/components/layout/Selector'));
const V1Features = dynamic(() => import('@/components/layout/Features'));
const V2Selector = dynamic(() => import('@/components/layout/V2Selector'));
const V7Selector = dynamic(() => import('@/components/layout/Selector'));
const V7Features = dynamic(() => import('@/components/layout/Features'));
const V2Features = dynamic(() => import('@/components/layout/V2Features'));
const AssistSelector = dynamic(
  () => import('@/components/layout/AssistSelector')
);
const AssistFeatures = dynamic(
  () => import('@/components/layout/AssistFeatures')
);
const V4Selector = dynamic(() => import('@/components/layout/v4/Selector'));
const V4Features = dynamic(() => import('@/components/layout/v4/Features'));
const V5Selector = dynamic(() => import('@/components/layout/v5/Selector'));
const V5Features = dynamic(() => import('@/components/layout/v5/Features'));
const LandingForm = dynamic(() => import('@/components/layout/LandingForm'));
const MemberLanding = dynamic(() => import('@/components/MemberLanding'));
const Services = dynamic(() => import('@/components/layout/login/Services'));
const Selector = dynamic(() => import('@/components/layout/login/Selector'));
const Features = dynamic(() => import('@/components/layout/login/Features'));

export default function Home(): JSX.Element {
  const theme = useTheme();
  const [customerId, setCustomerId] = useState<string>(null);
  const { actions } = useStateMachine({
    resetForm,
  });
  const TITLE = process.env.NEXT_PUBLIC_TITLE;
  const isV1 = theme.landingPage === 'v1';
  const isV2 = theme.landingPage === 'v2';
  const isV3 = theme.landingPage === 'v3';
  const isV4 = theme.landingPage === 'v4';
  const isV5 = theme.landingPage === 'v5';
  const isV6 = theme.landingPage === 'v6';
  const isV7 = theme.landingPage === 'v7';
  const isV8 = theme.landingPage === 'v8';
  const isV9 = theme.landingPage === 'v9';
  const isV10 = theme.landingPage === 'v10';
  const isV11 = theme.landingPage === 'v11';
  const isV12 = theme.landingPage === 'v12';
  const isV13 = theme.landingPage === 'v13';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const customerId = localStorage.getItem('customerId');
      if (customerId) {
        setCustomerId(customerId);
      }
    }
    actions.resetForm(null);
  }, []);

  return (
    <>
      <Head title={`${TITLE} - Apply`} theme={theme} />

      {!customerId ? (
        <>
          {isV1 || isV8 || isV9 || isV10 || isV11 ? (
            <>
              <V1Selector theme={theme} />
              <V1Features theme={theme} />
            </>
          ) : isV2 ? (
            <div className="container mx-auto sm:px-32">
              <V2Selector theme={theme} />
              <V2Features theme={theme} />
            </div>
          ) : isV3 ? (
            <div className="container mx-auto md:px-28">
              <AssistSelector theme={theme} />
              <AssistFeatures theme={theme} />
            </div>
          ) : isV4 ? (
            <div className="container mx-auto md:px-28">
              <V4Selector theme={theme} />
              <V4Features theme={theme} />
            </div>
          ) : isV5 ? (
            <div className="container mx-auto">
              <V5Selector theme={theme} />
              <V5Features theme={theme} />
            </div>
          ) : isV6 ? (
            <div className="container mx-auto md:px-32">
              <LandingForm />
            </div>
          ) : isV7 ? (
            <>
              <V7Selector theme={theme} />
              <V7Features theme={theme} />
            </>
          ) : isV12 ? (
            <div className="min-h-screen">
              <V12LandingPage />
            </div>
          ) : isV13 ? (
            <>
              <V13Landing />
            </>
          ) : (
            <>
              <Services theme={theme} />
              <Selector theme={theme} />
              <Features theme={theme} />
            </>
          )}
        </>
      ) : (
        <MemberLanding />
      )}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const result = await getTheme(context);
  const IS_BANK = process.env.NEXT_PUBLIC_IS_BANK === 'true';
  const host = context.req.headers.host;
  const domain = host.substring(host.indexOf('.') + 1);

  if (IS_BANK) {
    return {
      redirect: {
        destination: `https://${domain}`,
        permanent: false,
      },
    };
  }

  return result;
};
